import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Image } from "cloudinary-react"
const cldData = require("../../config.json")
import { toSentenceCase } from "../utils/utils"

// Transformations not compatible when using lazyload

const ImageMeta = props => {
  var cldImages = cldData.images.resources
  let alt = ""

  if (!props.publicId) {
    return <></>
  }
  for (let i = 0; i < cldImages.length; i++) {
    if (cldImages[i].context && props.publicId === cldImages[i].public_id) {
      alt = cldImages[i].context.custom.alt
      break
    } else {
      const splitName = props.publicId.split("/DEV/") //split cloudinary prefix
      const name = splitName[1] || splitName[0]
      alt = toSentenceCase(name.replace(/[/_-]/g, " "))
    }
  }

  var quality = 99
  var dataSrc = `https://res.cloudinary.com/nuvolum/image/upload/c_scale,f_auto,q_auto/v1/${props.publicId}`

  var imageClasses = classNames(props.className, {})

  return (
    <Image
      data-src={dataSrc}
      quality={quality}
      alt={alt}
      responsive
      {...props}
      noLazyload={undefined}
      className={imageClasses}
    >
      {props.children}
    </Image>
  )
}

ImageMeta.propTypes = {
  publicId: PropTypes.string.isRequired,
  children: PropTypes.element,
  noLazyload: PropTypes.bool,
  className: PropTypes.string
}

export default ImageMeta
