/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, pathname, schemaPage, robots }) {
  const { site, schema} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
        schema: allDataJson(filter: {schemaGeneral: {ne: null}}) {
          nodes {
            schemaGeneral
          }
          }
      }
    `
  )

  var schemaGeneral;

  const metaSchemaPage = schemaPage || null

  schemaGeneral = schema.nodes[0].schemaGeneral

  const metaRobots = robots;

  const metaDescription = description || site.siteMetadata.description

  const siteUrl = site.siteMetadata.siteUrl

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `https://res.cloudinary.com/nuvolum/image/upload/w_1200,h_627/v1592497138/FLSD/DEV/social-sharing-image.jpg`,
        },
        {
          property: `og:image:width`,
          content: `1200`,
        },
        {
          property: `og:image:height`,
          content: `627`,
        },
        {
          property: `og:image:alt`,
          content: `Full Smile Dental`,
        },
        {
          property: `og:url`,
          content: `${siteUrl}${pathname || "/"}`,
        },
        {
          name: `robots`,
          content: metaRobots
        }
      ].concat(meta)}
    >
      <link href="https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,400;0,600;0,700;0,800;1,400&display=swap" rel="stylesheet"></link>
      <script type="application/ld+json">{metaSchemaPage || metaSchemaPage != null ? metaSchemaPage : schemaGeneral}</script>

    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  schemaPage: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
