import { Link, StaticQuery, graphql } from "gatsby"
import React from "react"
import moment from "moment"

import { func } from "prop-types"
import ImageMeta from "../ImageMeta"
import SocialIcon from "./SocialIcon"
import GeneralHours from "@bit/azheng.joshua-tree.general-hours"
import CopyToClipboard from "../CopyToClipboard"
import BulmaColumns from "../Bulma/BulmaColumns"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

var classNames = require("classnames")
var widont = require("widont")

const socialIconFillColor = "#ffffff"

export default class ComFooter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.toggleFooter = this.toggleFooter.bind(this)
  }

  toggleFooter() {}

  render() {
    var comfooterClass = classNames({
      "com-footer": true,
      "color-back": true
    })

    return (
      <StaticQuery
        query={graphql`
          query footerMyQuery {
            allLocationsJson(sort: { fields: locationData___order }) {
              nodes {
                title
                locationData {
                  ...locationDataFields
                }
                pageData {
                  title
                }
              }
            }
            footerJson {
              footerItems {
                blurbText
                blurbTextEs
                logo
              }
              mainLocation
              missionBlurb
              features {
                linkText
                href
              }
            }
            site {
              siteMetadata {
                fullPracticeName
              }
            }
          }
        `}
        render={data => {
          let siteMetadata = data.site.siteMetadata

          let locationOne = data.allLocationsJson.nodes[0].locationData
          let locationTwo = data.allLocationsJson.nodes[1].locationData
          let locationThree = data.allLocationsJson.nodes[2].locationData
          let locationFour = data.allLocationsJson.nodes[3].locationData
          let locationFive = data.allLocationsJson.nodes[4].locationData
          let footerItems = data.footerJson.footerItems

          function ExtraPagesLinks({ isFirstHalf, isLastHalf, language }) {
            if (isFirstHalf) {
              return (
                <div>
                  {language !== "es" &&
                    locationOne.extraLinks &&
                    locationOne.extraLinks[0].statements.map(
                      (item, i) =>
                        i < 2 && (
                          <p className="footer extra-links" key={item.id}>
                            <Link to={`/${item.pageLink}`} title={item.title}>
                              {item.linkText}
                            </Link>
                          </p>
                        )
                    )}
                </div>
              )
            } else {
              return (
                <>
                  <div>
                    {language !== "es" &&
                      locationOne.extraLinks &&
                      locationOne.extraLinks[0].statements.map(
                        (item, i) =>
                          i >= 2 && (
                            <p className="footer extra-links" key={item.id}>
                              <Link to={`/${item.pageLink}`} title={item.title}>
                                {item.linkText}
                              </Link>
                            </p>
                          )
                      )}
                  </div>
                  <div
                    className="column footer-social"
                    style={{ marginTop: "20px" }}
                  >
                    <div className="column is-2" />

                    <div className="columns">
                      {locationOne.socialAccounts &&
                        locationOne.socialAccounts.map((item, i) => (
                          <React.Fragment key={i}>
                            <p className={`small social-icon ${item.platform}`}>
                              <a
                                href={item.link}
                                target="_blank"
                                title={item.platform}
                              >
                                <SocialIcon
                                  fillColor={socialIconFillColor}
                                  socialAccount={item.platform}
                                />
                              </a>
                            </p>
                          </React.Fragment>
                        ))}
                    </div>
                    <div className="column is-2" />
                  </div>
                </>
              )
            }
          }

          const seoHiddenFooterLink =
            this.props.pageTitle == "cosmetic-dentistry/" ? (
              <p class="cosmetic" style={{ fontSize: 12 }}>
                <a
                  href="https://nuvolum.com/plastic-surgery-marketing/"
                  title="Cosmetic &amp; Plastic Surgery Marketing by Nuvolum.com"
                  target="_blank"
                  style={{ color: "white", fontWeight: "normal" }}
                >
                  Cosmetic &amp; Plastic Surgery Marketing
                </a>{" "}
                by Nuvolum
              </p>
            ) : (
              <></>
            )

          return (
            <>
              {/* Desktop footer */}
              <footer
                id={this.props.pageTitle}
                className={comfooterClass + "" + this.props.pageTitle}
              >
                <div
                  className="columns is-centered is-vcentered is-desktop footer-top"
                  style={{ paddingBottom: "2rem" }}
                >
                  <div className="column is-3 footer-logo">
                    <ImageMeta
                      cloudName="nuvolum"
                      publicId={footerItems.logo}
                      width="auto"
                      noLazyload
                      responsive
                    ></ImageMeta>
                  </div>
                  <div className="column is-narrow">
                    {this.props.language === "es" ? (
                      <h5 className="footer-blurb-text">
                        {widont(footerItems.blurbTextEs)}
                      </h5>
                    ) : (
                      <h5 className="footer-blurb-text">
                        {widont(footerItems.blurbText)}
                      </h5>
                    )}
                  </div>
                </div>

                <div className="columns">
                  <div className="column is-1"></div>
                  <div className="column">
                    <div className="columns">
                      <div className="column">
                        <div className="column footer-mission">
                          <OurMission blurb={data.footerJson.missionBlurb} />
                        </div>

                        <div className="sub-footer-copyright is-hidden-mobile footer-bottom-links">
                          <p className="footer">
                            © {moment().format("YYYY")}{" "}
                            {siteMetadata.fullPracticeName}. All rights
                            reserved.
                            {seoHiddenFooterLink}
                          </p>
                          <p
                            style={{ marginTop: "1rem" }}
                            className="nuvo-brand footer"
                            dangerouslySetInnerHTML={{
                              __html:
                                this.props.language === "es"
                                  ? 'Sitio Web y Producción de Video por <a href="https://nuvolum.com" target="_blank" title="Sitio Web y Producción de Video por Nuvolum">Nuvolum</a>'
                                  : 'Website, Video & Oral Surgery Marketing by&nbsp;<a href="https://nuvolum.com" target="_blank" title="Website, Video & Oral Surgery Marketing by Nuvolum">Nuvolum</a>'
                            }}
                          ></p>
                          {this.props.language === "en" && (
                            <p className="nuvo-brand footer">
                              <a
                                style={{ fontWeight: "bold" }}
                                href="https://getwuwta.com/"
                                title="WUWTA - The Patient Communication Platform That Delivers Superior Outcomes"
                                target="_blank"
                              >
                                Patient experience powered by WUWTA™
                              </a>
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="column is-2"></div>

                      <div className="column is-5">
                        <ContactUs locations={data.allLocationsJson.nodes} />
                        <div className="is-hidden-mobile footer-bottom-links">
                          <ExtraPagesLinks
                            language={this.props.language}
                            isFirstHalf
                          />
                        </div>
                      </div>

                      <div className="column is-2"></div>

                      <div className="column">
                        <Features features={data.footerJson.features} />

                        <div className="is-hidden-mobile footer-bottom-links">
                          <ExtraPagesLinks
                            language={this.props.language}
                            isLastHalf
                          />
                        </div>
                      </div>
                      <div className="column is-hidden-desktop is-hidden-tablet">
                        <div className="mobile-statements">
                          <ExtraPagesLinks
                            language={this.props.language}
                            isFirstHalf
                          />
                          <ExtraPagesLinks
                            language={this.props.language}
                            isLastHalf
                          />
                        </div>
                        <div className="sub-footer-copyright is-hidden-desktop has-text-centered">
                          <p className="footer">
                            © {moment().format("YYYY")}{" "}
                            {siteMetadata.fullPracticeName}. All rights
                            reserved.
                            {seoHiddenFooterLink}
                          </p>
                          <p
                            style={{ marginTop: "1rem" }}
                            className="mobile-nuvo-brand"
                            dangerouslySetInnerHTML={{
                              __html:
                                this.props.language === "es"
                                  ? 'Sitio Web y Producción de Video por <a href="https://nuvolum.com" target="_blank" title="Sitio Web y Producción de Video por Nuvolum">Nuvolum</a>'
                                  : 'Website, Video & Oral Surgery Marketing by&nbsp;<a href="https://nuvolum.com" target="_blank" title="Website, Video & Oral Surgery Marketing by Nuvolum">Nuvolum</a>'
                            }}
                          ></p>
                          {this.props.language === "en" && (
                            <p className="nuvo-brand">
                              <a
                                style={{ fontWeight: "bold", fontSize: "12px" }}
                                href="https://getwuwta.com/"
                                title="WUWTA - The Patient Communication Platform That Delivers Superior Outcomes"
                                target="_blank"
                              >
                                Patient experience powered by WUWTA™
                              </a>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column is-1"></div>
                </div>
              </footer>
            </>
          )
        }}
      />
    )
  }
}

function OfficeSection(props) {
  return (
    <div className="office-section">
      <h7>
        {props.language === "es"
          ? "Dirección Oficina del Este"
          : props.location.locationName + " Office"}
      </h7>

      <p className="small footer-location-address">
        <a
          href={props.location.googlePlaceLink}
          target="_blank"
          title="Get directions"
        >
          {props.location.streetNumber + " "}
          {props.location.streetName}
          <br />
          {props.location.cityName}
          {", " + props.location.stateAbbr + " "}
          {props.location.cityZip}
        </a>

        <p className="small">
          <a href={"tel:+" + props.location.phone} title="Call us">
            {props.location.phone}{" "}
          </a>
        </p>

        {/* <p className="small"><a href={`mailto:${props.location.email}`} title="Email us">{props.location.email}</a></p> */}
        <CopyToClipboard
          email={props.location.email}
          language={props.language}
        />
      </p>
    </div>
  )
}

function OurMission({ blurb }) {
  return (
    <div className="column footer-mission">
      <FooterHeading heading="Our Mission" />
      <MarkdownViewer markdown={blurb} />
    </div>
  )
}

function FooterHeading({ heading }) {
  return (
    <BulmaColumns className="is-mobile is-vcentered footer-heading">
      <div className="column is-narrow-tablet">
        <h6>{heading}</h6>
      </div>
      <div className="column is-hidden-mobile">
        <span className="hline"></span>
      </div>
    </BulmaColumns>
  )
}

function ContactUs({ locations }) {
  const txLocations = []

  locations.sort((a, b) => {
    let cityA = a.locationData.cityName.toLowerCase()
    let cityB = b.locationData.cityName.toLowerCase()

    //sort string ascending
    if (cityA < cityB) return -1
    if (cityA > cityB) return 1
    return 0
  })

  locations.forEach(location => {
    if (location.locationData.cityName != "Amarillo") {
      txLocations.push(location)
    }
  })

  return (
    <div className="column footer-contact">
      <FooterHeading heading="Contact Us" />
      <BulmaColumns>
        <div className="column footer-state-col">
          <div>
            <Link
              to={`/${locations[0].pageData.title}`}
              title={locations[0].locationData.cityName}
            >
              {locations[0].locationData.cityName} Offices
            </Link>
            {txLocations.map(location => {
              return (
                <div>
                  <Link
                    to={`/${location.pageData.title}`}
                    title={location.locationData.cityName}
                  >
                    {location.locationData.cityName} Office
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </BulmaColumns>
    </div>
  )
}

function Features({ features }) {
  // Sort alphabetically on linkText
  // features = features.sort(function (a, b) {
  //     a = a.linkText;
  //     b = b.linkText;
  //     return (a < b) ? -1 : (a > b) ? 1 : 0;
  // });

  let leftColFeatures = []
  let rightColFeatures = []

  // Split between left and right columns
  for (let i = 0; i < features.length; i++) {
    if (i % 2 === 0) {
      leftColFeatures.push(features[i])
    } else {
      rightColFeatures.push(features[i])
    }
  }

  return (
    <div className="column footer-features">
      <FooterHeading heading="Features" />
      <BulmaColumns>
        <div className="column">
          <FeatureList features={leftColFeatures} />
        </div>
        <div className="column is-2"></div>
        <div className="column">
          <FeatureList features={rightColFeatures} />
        </div>
      </BulmaColumns>
    </div>
  )
}

function FeatureList({ features }) {
  return (
    <ul>
      {features.map(feature => {
        return feature.linkText.includes("BOTOX") ? (
          <li key={feature.href}>
            <Link
              className="footer-page-link"
              to={feature.href}
              dangerouslySetInnerHTML={{ __html: "BOTOX<sup>®</sup>" }}
            ></Link>
          </li>
        ) : (
          <li key={feature.href}>
            <Link className="footer-page-link" to={feature.href}>
              {feature.linkText}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}
